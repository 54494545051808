import React, { Suspense } from "react";
import "./App.scss";
import { Route, Routes, Outlet } from "react-router-dom";
import Loading from "./views/pages/Loading/Loading.jsx";
import { OperatorProvider } from "./context/OperatorContextNew";
import { DisplayProvider } from "./context/DisplayContextNew";
import { DashboardProvider } from "./context/DashboardContextNew";
import { UserRegisterProvider } from "./context/UserRegisterContextNew";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// Dahboard Page
const Dashboard = React.lazy(() => import("./views/pages/Dashboard/Dashboard"));
const Pengguna = React.lazy(() =>
	import("./views/pages/Dashboard/Pengguna/Pengguna")
);
const ListTenant = React.lazy(() =>
	import("./views/pages/Dashboard/Tenant/ListTenant")
);
const ListKategori = React.lazy(() =>
	import("./views/pages/Dashboard/Kategori/ListKategori")
);
const ListLayanan = React.lazy(() =>
	import("./views/pages/Dashboard/Layanan/Layanan")
);
const ListLayananNonAktif = React.lazy(() =>
	import("./views/pages/Dashboard/LayananNonAktif/LayananNonAktif")
);
const ListUserLayanan = React.lazy(() =>
	import("./views/pages/Dashboard/UserLayanan/ListUserLayanan")
);
const LoketLayananCabang = React.lazy(() =>
	import("./views/pages/Dashboard/LoketLayananCabang/LoketLayananCabang")
);
const ListLoket = React.lazy(() =>
	import("./views/pages/Dashboard/Loket/ListLoket")
);
const ListRT = React.lazy(() =>
	import("./views/pages/Dashboard/RunningText/ListRT")
);
const ListBanner = React.lazy(() =>
	import("./views/pages/Dashboard/Banner/ListBanner")
);
const ListCabangTenant = React.lazy(() =>
	import("./views/pages/Dashboard/CabangTenant/ListCabangTenant")
);
const Konfigurasi = React.lazy(() =>
	import("./views/pages/Dashboard/Konfigurasi/Konfigurasi")
);
const HariLibur = React.lazy(() =>
	import("./views/pages/Dashboard/HariLibur/HariLibur")
);
const Tema = React.lazy(() => import("./views/pages/Dashboard/Theme/Theme"));
const LaporanDilayani = React.lazy(() =>
	import("./views/pages/Dashboard/Laporan/LaporanDilayani/LaporanDilayani.jsx")
);
const LaporanMenunggu = React.lazy(() =>
	import("./views/pages/Dashboard/Laporan/LaporanMenunggu/LaporanMenunggu.jsx")
);
const LaporanFileEform = React.lazy(() =>
	import(
		"./views/pages/Dashboard/Laporan/LaporanFileEform/LaporanFileEform.jsx"
	)
);
const LaporanNoShow = React.lazy(() =>
	import("./views/pages/Dashboard/Laporan/LaporanNoShow/LaporanNoShow.jsx")
);
const LaporanSurvey = React.lazy(() =>
	import("./views/pages/Dashboard/Laporan/LaporanSurvey/LaporanSurvey.jsx")
);
const LaporanAuditTrail = React.lazy(() =>
	import("./views/pages/Dashboard/Laporan/AuditTrail/AuditTrail.jsx")
);
const MasterEform = React.lazy(() =>
	import("./views/pages/Dashboard/Eform/Eform.jsx")
);
const ResetAntrian = React.lazy(() =>
	import("./views/pages/Dashboard/ResetAntrian/ResetAntrian.jsx")
);
const DisplayDashboard = React.lazy(() =>
	import("./views/pages/Dashboard/Display/Display.jsx")
);
const Monitoring = React.lazy(() =>
	import("./views/pages/Dashboard/Monitoring/Monitoring.jsx")
);
// const LayananTransfer = React.lazy(() => import("./views/pages/Dashboard/LayananTransfer/LayananTransfer"));

// Login User Page
const LoginUser = React.lazy(() =>
	import("./views/pages/LoginUser/Login/LoginUser")
);
const RegisterUser = React.lazy(() =>
	import("./views/pages/LoginUser/Register/RegisterUser")
);
const RegisterHp = React.lazy(() =>
	import("./views/pages/LoginUser/RegisterHp/RegisterHp")
);
const AfterRegisterUser = React.lazy(() =>
	import("./views/pages/LoginUser/AfterRegister/AfterRegisterUser")
);
const VerificationUser = React.lazy(() =>
	import("./views/pages/LoginUser/Verification/VerificationUser")
);
const ForgotPasswordUser = React.lazy(() =>
	import("./views/pages/LoginUser/ForgotPassword/ForgotPasswordUser")
);
const NewPasswordUser = React.lazy(() =>
	import("./views/pages/LoginUser/NewPassword/NewPasswordUser")
);
const Profile = React.lazy(() => import("./views/pages/User/Profile/Profile"));
const ResetPassword = React.lazy(() =>
	import("./views/pages/LoginUser/NewPassword/NewPasswordUser.jsx")
);
const RiwayatPesanan = React.lazy(() =>
	import("./views/pages/User/RiwayatPesanan/RiwayatPesanan.jsx")
);

// * Operator Page
const Operator = React.lazy(() => import("./views/pages/Operator/Operator"));
// * Caller Page
const Caller = React.lazy(() => import("./views/pages/Caller/Caller"));
// * User Page
const User = React.lazy(() => import("./views/pages/User/User"));
const NotFound = React.lazy(() => import("./views/pages/NotFound/NotFound"));
const Tenant = React.lazy(() => import("./views/pages/User/Tenant/Tenant"));
const LayananOnSite = React.lazy(() =>
	import("./views/pages/User/Layanan/LayananOnSite")
);
const Eform = React.lazy(() => import("./views/pages/User/Eform/Eform"));
const Eticket = React.lazy(() => import("./views/pages/User/Eticket/Eticket"));
const Kategori = React.lazy(() =>
	import("./views/pages/User/Kategori/Kategori")
);
const Kios = React.lazy(() => import("./views/pages/Kios/Kios"));
const EticketOnSite = React.lazy(() =>
	import("./views/pages/User/Eticket/EticketOnSite.jsx")
);
const Layanan = React.lazy(() => import("./views/pages/User/Layanan/Layanan"));
// * Display
const Display = React.lazy(() => import("./views/pages/Display/Display"));
const NewDisplay = React.lazy(() =>
	import("./views/pages/NewDisplay/NewDisplay")
);
const BigDisplay = React.lazy(() => import("./views/pages/Display/BigDisplay"));
// * Router
const RouteUser = React.lazy(() => import("./routes/RouteUser"));
const RouteDashboard = React.lazy(() => import("./routes/RouteDashboard"));
const RouteOperator = React.lazy(() => import("./routes/RouteOperator"));
const RouteDisplay = React.lazy(() => import("./routes/RouteDisplay"));
const RouteNewDisplay = React.lazy(() => import("./routes/RouteNewDisplay"));
const RouteBigDisplay = React.lazy(() => import("./routes/RouteBigDisplay"));
const RouteCaller = React.lazy(() => import("./routes/RouteCaller"));
// * Layout
const LayoutUser = React.lazy(() =>
	import("./views/containers/User/LayoutUser")
);
const LayoutOperator = React.lazy(() =>
	import("./views/containers/Operator/LayoutOperator")
);
const LayoutCaller = React.lazy(() =>
	import("./views/containers/Caller/LayoutCaller")
);
const LayoutDashboard = React.lazy(() =>
	import("./views/containers/Dashboard/LayoutDashboard")
);
const LayoutOnSite = React.lazy(() =>
	import("./views/containers/User/LayoutOnSite")
);
const LayoutMonitoring = React.lazy(() =>
	import("./views/containers/Monitoring/LayoutMonitoring")
);

const Login = React.lazy(() => import("./views/pages/Login/Login.jsx"));
const LoginCaller = React.lazy(() =>
	import("./views/pages/LoginCaller/LoginCaller.jsx")
);

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
// 	"pdfjs-dist/build/pdf.worker.min.js",
// 	import.meta.url
// ).toString();

export default function App() {
	return (
		<Suspense fallback={<Loading />}>
			<Routes>
				<Route path="/" element={<Homepage />}>
					<Route element={<RouteUser />}>
						{/* <Route index element={<User />} /> */}
						<Route index element={<LoginUser />} />
						<Route path="/register" element={<RegisterUser />} />
						<Route path="/register-hp" element={<RegisterHp />} />
						<Route path="/after-register" element={<AfterRegisterUser />} />
						<Route path="/forgot-password" element={<ForgotPasswordUser />} />
						<Route path="/new-password" element={<NewPasswordUser />} />
						<Route path="/verification" element={<VerificationUser />} />
						<Route path="/reset-password" element={<ResetPassword />}>
							<Route path=":token" element={<ResetPassword />} />
						</Route>
						<Route element={<LayoutUser />}>
							<Route path="/pilih-kota" element={<User />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/tenant" element={<Tenant />} />
							<Route path="/kategori" element={<Kategori />} />
							<Route path="/layanan" element={<Layanan />} />
							<Route path="/eform" element={<Eform />} />
							<Route path="/riwayat-pesanan" element={<RiwayatPesanan />} />
							<Route path="/eticket" element={<Eticket />}>
								<Route path=":uniqueCode" element={<Eticket />} />
							</Route>
						</Route>
						<Route element={<LayoutOnSite />}>
							<Route path="/layanan-on-site" element={<LayananOnSite />}>
								<Route path=":cabangId" element={<LayananOnSite />} />
							</Route>
							<Route path="/eticket-on-site" element={<EticketOnSite />}>
								<Route path=":uniqueCode" element={<EticketOnSite />} />
							</Route>
							<Route path="/mkios" element={<Kios />}>
								<Route path=":kode" element={<Kios />} />
							</Route>
						</Route>
					</Route>
					<Route element={<RouteDashboard />}>
						<Route element={<LayoutDashboard />}>
							<Route index path="/dashboard" element={<Dashboard />} />
							<Route path="/dashboard/pengguna" element={<Pengguna />} />
							<Route path="/dashboard/kategori" element={<ListKategori />} />
							<Route path="/dashboard/master/tenant" element={<ListTenant />} />
							<Route
								path="/dashboard/master/user-layanan"
								element={<ListUserLayanan />}
							/>
							<Route
								path="/dashboard/master/loket-layanan-cabang"
								element={<LoketLayananCabang />}
							/>
							<Route path="/dashboard/master/loket" element={<ListLoket />} />
							<Route
								path="/dashboard/master/cabang"
								element={<ListCabangTenant />}
							/>
							<Route
								path="/dashboard/master/layanan"
								element={<ListLayanan />}
							/>
							<Route
								path="/dashboard/master/layanan-non-aktif"
								element={<ListLayananNonAktif />}
							/>
							<Route path="/dashboard/master/eform" element={<MasterEform />} />
							<Route
								path="/dashboard/master/display"
								element={<DisplayDashboard />}
							/>
							<Route path="/dashboard/parameter/rt" element={<ListRT />} />
							<Route
								path="/dashboard/parameter/banner"
								element={<ListBanner />}
							/>
							<Route path="/dashboard/konfigurasi" element={<Konfigurasi />} />
							<Route path="/dashboard/hari-libur" element={<HariLibur />} />
							<Route path="/dashboard/tema" element={<Tema />} />
							<Route
								path="/dashboard/reset-antrian"
								element={<ResetAntrian />}
							/>
							<Route
								path="/dashboard/laporan/laporan-dilayani"
								element={<LaporanDilayani />}
							/>
							<Route
								path="/dashboard/laporan/laporan-menunggu"
								element={<LaporanMenunggu />}
							/>
							<Route
								path="/dashboard/laporan/laporan-file-eform"
								element={<LaporanFileEform />}
							/>
							<Route
								path="/dashboard/laporan/laporan-no-show"
								element={<LaporanNoShow />}
							/>
							<Route
								path="/dashboard/laporan/laporan-survey"
								element={<LaporanSurvey />}
							/>
							<Route
								path="/dashboard/laporan/audit-trail"
								element={<LaporanAuditTrail />}
							/>
						</Route>
					</Route>
					<Route element={<RouteOperator />}>
						<Route element={<LayoutOperator />}>
							<Route index path="/operator" element={<Operator />} />
						</Route>
					</Route>
					<Route element={<RouteCaller />}>
						<Route element={<LayoutCaller />}>
							<Route index path="/caller" element={<Caller />} />
						</Route>
					</Route>
					<Route path="/display" element={<RouteDisplay />}>
						<Route path=":displayId" element={<Display />} />
					</Route>
					<Route path="/new-display" element={<RouteNewDisplay />}>
						<Route path=":displayId" element={<NewDisplay />} />
					</Route>
					<Route path="/big-display" element={<RouteBigDisplay />}>
						<Route path=":displayId" element={<BigDisplay />} />
					</Route>
					<Route path="spv/monitoring" element={<LayoutMonitoring />}>
						<Route path=":spvId" element={<Monitoring />} />
					</Route>
					<Route path="*" element={<NotFound />} />
					<Route path="/login" element={<Login />} />
					<Route path="/login-caller" element={<LoginCaller />} />
				</Route>
			</Routes>
		</Suspense>
	);
}

function Homepage() {
	return (
		<div style={{ height: "100%", width: "100vw" }}>
			<UserRegisterProvider>
				<OperatorProvider>
					<DisplayProvider>
						<DashboardProvider>
							<Outlet />
						</DashboardProvider>
					</DisplayProvider>
				</OperatorProvider>
			</UserRegisterProvider>
		</div>
	);
}
